import React from "react";
import { Wrapper } from "../components/site/Wrapper";
import { graphql } from "gatsby";
import { Card, USALinks, NatureLinks, BibleLinks, SportsLinks, ProfessionsLinks, CityLifeLinks, WorldLinks, HolidaysLinks, FoodDrinkLinks } from ".";

export const query = graphql`
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          published
          comingSoon
          sortLevel
          shortUrl
          showInPuzzles
          pages
        }
      }
    }
  }
`;

const Puzzles = () => {



  return (
    <Wrapper>
    <div className="">
      <div className="container">
        <h1 className="fw-bold mx-2 col-lg-8">Puzzles</h1>
        <div className="row">

        <Card name="USA" mainLink="/puzzles/wordsearch/usa/" links={USALinks} amount={101}/>
        <Card name="Nature" mainLink="/puzzles/wordsearch/nature/" links={NatureLinks} amount={101}/>
        <Card name="Bible" mainLink="/puzzles/wordsearch/bible/" links={BibleLinks} amount={101}/>
        <Card name="Sports" mainLink="/puzzles/wordsearch/sports/" links={SportsLinks} amount={101}/>
        <Card name="World Cities" mainLink="/puzzles/wordsearch/world/" links={WorldLinks} amount={18} />
        <Card name="Professsions" mainLink="/puzzles/wordsearch/professions/" links={ProfessionsLinks} amount={101} />
        <Card name="Holidays" mainLink="/puzzles/wordsearch/holidays/" links={HolidaysLinks} amount={12} />
        <Card name="City Life" mainLink="/puzzles/wordsearch/citylife/" links={CityLifeLinks} amount={101} />
        <Card name="Food & Drink" mainLink="/puzzles/wordsearch/food/" links={FoodDrinkLinks} amount={101} />

        </div>
      </div>
    </div>
    </Wrapper>
  );
};

export default Puzzles;
